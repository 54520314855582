<template>
  <v-card>
    <v-card-title>Nested{{ this.$route.query }}</v-card-title>
    <v-card-text>
      <nestedDraggable :tasks="list" />
    </v-card-text>
  </v-card>
</template>

<script>
import nestedDraggable from './nested.vue'

export default {
  components: {
    nestedDraggable
  },
  data() {
    return {
      list: [
        {
          name: "task 1",
          tasks: [
            {
              name: "task 2",
              tasks: []
            }
          ]
        },
        {
          name: "task 3",
          tasks: [
            {
              name: "task 4",
              tasks: []
            }
          ]
        },
        {
          name: "task 5",
          tasks: []
        }
      ]
    };
  }
}
</script>

<style>

</style>
