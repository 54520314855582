<template>
  <v-card
    class="mx-auto mt-12"
    max-width="450"
  >
    <v-system-bar />




    <v-list three-line>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        ripple
        @click="() => {onClick(item)}"
      >
        <v-img
          :src="item.image"
          class="mr-4"
          max-width="64"
          min-width="64"
        />

        <v-list-item-content>
          <span
            class="text-uppercase font-weight-regular text-caption"
            v-text="item.name"
          />

          <div v-text="item.description" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name:'WidgetList',
  components:{

  },
  data: function() {
    return {
      items:[
        {image:'',name:'widget1',description:'widget1 desc',to:'https://player.vimeo.com/video/137857207'},
        {image:'',name:'widget2',description:'widget2 desc',to:'http://google.com'},
        {image:'',name:'widget3',description:'widget3 desc',to:'http://daum.net'},
        {image:'',name:'widget4',description:'widget4 desc',to:'https://vuetifyjs.com/'},
      ]
    }
  },
  methods:{
    onClick(item){
      this.$emit('click',item)

    }
  }
}
</script>

<style>

</style>
