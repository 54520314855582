<template>
  <Menu
    ref="menus"
    :items="items"
    :is-config="isConfig"
    @click="onClick"
  />
</template>

<script>
import Menu from './Menu.vue'
export default {
  name:'Menus',
  components: {
    Menu
  },
  props: {

    items: {
      required: true,
      type: Array
    },
    isConfig: {
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      newItems:this.items,

    }
  },
  methods:{
    onChange(e){
      console.log(e)
    },
    onClick(item,e){
      if(this.isConfig){
        this.removeClass(this.$refs.menus.$el,'v-item--active');
        this.removeClass(this.$refs.menus.$el,'v-list-item--active');
        this.removeClass(this.$refs.menus.$el,'primary');
        this.removeClass(this.$refs.menus.$el,'white--text');
        // this.addClass(e.target,'primary');
        // this.addClass(e.target,'white--text');


      }
      this.$emit('click',item);
    },
    removeClass(el,className){
      let elements = el.querySelectorAll('.'+className);
      elements.forEach( (i, k) => {
        elements[k].classList.remove(className);
      });

    },
    addClass(el,className){
      el.classList.add(className);
    }

  }
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}
</style>
