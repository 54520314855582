<template>
  <v-list

    dense
    expand
    nav
    style="padding:0px"
  >
    <v-list-item-group>
      <draggable
        v-model="newItems"
        class="dragArea"
        tag="div"
        :group="{ name: 'g1' }"
        :disabled="!isConfig"
        @change="onChange"
      >
        <template
          v-for="item in newItems"
        >
          <template v-if="!isConfig">
            <v-list-item
              v-if="!item.items"
              :key="item.title"
              class="py-1"
              link
              :to="item.to"
              active-class="primary white--text"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-else
              :key="item.title"
              :prepend-icon="item.icon"
              eager
              v-bind="$attrs"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <Menu
                v-if="item.items"
                :items="item.items"
                :is-config="isConfig"
              />
            </v-list-group>
          </template>
          <template
            v-else
          >
            <v-list-item
              v-if="!item.items"
              :key="item.title"
              class="py-1"
              @click="(e)=>{onClick(item,e)}"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-else
              :key="item.title"
              :prepend-icon="item.icon"
              eager
              v-bind="$attrs"
              class="dragItem"
              @click="(e)=>{onClick(item,e)}"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <Menu
                v-if="item.items"
                :items="item.items"
                :is-config="isConfig"
                @click="onClick"
              />
            </v-list-group>
          </template>
        </template>
      </draggable>
    </v-list-item-group>
  </v-list>
</template>

<script>
import draggable from "vuedraggable";
export default {
  name:'Menu',
  components: {
    draggable
  },
  props: {

    items: {
      required: true,
      type: Array
    },
    isConfig: {
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      newItems:Object.assign([],this.items),

    }
  },
  methods:{
    onChange(e){
      console.log(e)
    },
    onClick(item,e){
      this.$emit('click',item,e);
    }

  }
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  outline: 1px dashed;
}

</style>
