<template>
  <draggable
    class="dragArea"
    tag="ul"
    :list="tasks"
    :group="{ name: 'g1' }"
  >
    <!-- <li
      v-for="el in tasks"
      :key="el.name"
    >
      <p>{{ el.name }}</p>
      <nested-draggable :tasks="el.tasks" />
    </li> -->


    <v-card
      v-for="el in tasks"
      :key="el.name"
      elevation="2"
      class="ma-2 pa-1"
    >
      <p>{{ el.name }}</p>
      <nested-draggable :tasks="el.tasks" />
    </v-card>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "NestedDraggable",
  components: {
    draggable
  },
  props: {
    tasks: {
      required: true,
      type: Array
    }
  }
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  /* outline: 1px dashed; */

}
</style>
