<template>
  <v-container fluid>
    <v-row>
      <v-btn @click="selectWidget">
        Add
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <grid-layout
          :layout.sync="layout"
          :col-num="12"
          :row-height="30"
          :is-draggable="true"
          :is-resizable="true"
          :is-mirrored="false"
          :vertical-compact="true"
          :margin="[10, 10]"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="item in layout"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
          >
            <v-card>
              <!-- <v-toolbar
                color="indigo"
                dark
              > -->
              <v-card-title>
                <v-row>
                  <span>{{ item.name }}</span>
                  <v-spacer />

                  <v-btn icon>
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-btn>
                </v-row>
              </v-card-title>

              <!-- </v-toolbar> -->
            </v-card>


            <!-- {{ item.i }} -->
            <!-- <span
              class="remove"
              @click="removeItem(item.i)"
            >x</span> -->
            <iframe
              v-if="item.to"
              :src="item.to"
              height="100%"
              width="100%"
              frameborder="0"
            />
          </grid-item>
        </grid-layout>
      </v-col>
      <v-dialog
        v-model="dialog"
        max-width="700"
      >
        <v-card>
          <v-card-title class="text-h5">
            Select Widget
          </v-card-title>
          <v-card-text><widget-list @click="onSelectWidget" /></v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import WidgetList from './WidgetList.vue';
import { mdiCogOutline } from '@mdi/js';
export default {
  name:'DashboardEditor',
  components:{
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    WidgetList,
  },
  data: function() {
    return {
      dialog:false,
	        layout: [
        // {"x":0,"y":0,"w":2,"h":2,"i":"0"},
        // {"x":2,"y":0,"w":2,"h":4,"i":"1"},
        // {"x":4,"y":0,"w":2,"h":5,"i":"2"},
        // {"x":6,"y":0,"w":2,"h":3,"i":"3"},
        // {"x":8,"y":0,"w":2,"h":3,"i":"4"},
        // {"x":10,"y":0,"w":2,"h":3,"i":"5"},
        // {"x":0,"y":5,"w":2,"h":5,"i":"6"},
        // {"x":2,"y":5,"w":2,"h":5,"i":"7"},
        // {"x":4,"y":5,"w":2,"h":5,"i":"8"},
        // {"x":6,"y":3,"w":2,"h":4,"i":"9"},
        // {"x":8,"y":4,"w":2,"h":4,"i":"10"},
        // {"x":10,"y":4,"w":2,"h":4,"i":"11"},
        // {"x":0,"y":10,"w":2,"h":5,"i":"12"},
        // {"x":2,"y":10,"w":2,"h":5,"i":"13"},
        // {"x":4,"y":8,"w":2,"h":4,"i":"14"},
        // {"x":6,"y":8,"w":2,"h":4,"i":"15"},
        // {"x":8,"y":10,"w":2,"h":5,"i":"16"},
        // {"x":10,"y":4,"w":2,"h":2,"i":"17"},
        // {"x":0,"y":9,"w":2,"h":3,"i":"18"},
        // {"x":2,"y":6,"w":2,"h":2,"i":"19"}
      ],
    }
  },
  mounted() {
    // this.$gridlayout.load();
    this.index = this.layout.length;

  },
  methods:{
    selectWidget(){
      this.dialog = true;
    },
    addItem(item) {

      // Add a new item. It must have a unique key!
      this.layout.push({
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 6,
        h: 6,
        i: this.index,
        to:item.to,
        name:item.name
      });
      // Increment the counter to ensure key is always unique.
      this.index++;

    },
    removeItem: function (val) {
      const index = this.layout.map(item => item.i).indexOf(val);
      this.layout.splice(index, 1);
    },
    onSelectWidget(e){
      console.log(e);
      this.addItem(e);
      this.dialog =false;
    }
  }
}
</script>

<style>
.container .vue-grid-item.vue-grid-placeholder {
    background: green;
}
.vue-grid-layout {
    background: #eee;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
.remove{
  float:right;

}
.remove:hover{
  cursor: pointer;
}
iframe{
  width:100%;
  height:calc(100% - 40px);
}
</style>
