<template>
  <v-container fluid>
    <v-card>
      <!-- <label>{{ menuItems }}</label> -->
      <!-- <v-card-title class="indigo white--text text-h5">
      Menu
    </v-card-title> -->
      <div style="width:100%;padding:5px">
        <v-btn
          class="float-right"
          @click="onSave"
        >
          Save
        </v-btn>
        <v-btn
          class="float-right"
          @click="onAdd"
        >
          Add
        </v-btn>
      </div>
      <v-row
        class="pa-4"
        justify="space-between"
      >
        <v-col cols="3">
          <Menus
            :key="reload"
            :items="menuItems"
            :is-config="true"
            @click="onClick"
          />
        </v-col>

        <v-divider vertical />

        <v-col
          class="d-flex text-center"
          cols="9"
        >
          <div style="width:100%;">
            <v-form
              ref="form"
              lazy-validation
            >
              <v-text-field
                v-model="selectedItem.title"
                :counter="20"
                label="Name"
                required
              />

              <v-text-field
                v-model="selectedItem.to"
                label="Url"
                required
              />
              <v-text-field
                v-model="selectedItem.description"
                label="Description"
              />

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="selectedItem.icon"
                    label="Icon"
                  />
                </v-col>
                <v-col cols="2">
                  <v-icon
                    large
                    @click="()=>{dialog=true}"
                  >
                    {{ selectedItem.icon!=null?selectedItem.icon:'mdi-image-off '
                    }}
                  </v-icon>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <v-card>
            <v-card-title class="text-h5">
              Select Icon
            </v-card-title>
            <v-card-text><icon-list @click="onIconClick" /></v-card-text>
            <v-card-actions>
              <v-spacer />

              <v-btn
                color="green darken-1"
                text
                @click="dialog = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import treeview from 'vuetify'
import IconList from '../IconList.vue'

import VuetifyDraggableTreeview from 'vuetify-draggable-treeview'
Vue.use(VuetifyDraggableTreeview)
import 'vuetify/dist/vuetify.min.css'
import {mapState } from 'vuex'
// import  DefaultList from '@/layouts/default/List.vue'
import Menus from '@/layouts/default/Menus.vue'
import draggable from "vuedraggable";

export default {
  components: {
    // DefaultList
    Menus,
    IconList,
    // draggable,
    // TreeList
  },
  data(){
    return {
      // menuItems:this.$store.app.items
      reload:1,
      dialog: false,
      menuItems:Object.assign([],this.$store.state.app.items),
      selectedItem: {},
      items: [
        { text: 'Real-Time', icon: 'mdi-clock' },
        { text: 'Audience', icon: 'mdi-account' },
        { text: 'Conversions', icon: 'mdi-flag' },
      ],
    }
  },
  computed:{
    // ...mapState('app',{items:'items'})
  },
  methods:{
    onClick(e){
      console.log(e)
      this.selectedItem = e;
    },
    onIconClick(e){
      this.selectedItem.icon= e;
      this.dialog = false;
    },
    onSave(){
      this.$store.state.app.items = Object.assign([],this.menuItems);
    },
    onAdd(){
      const menu = { title: 'newMenu', icon: '', to: ''};
      if(this.selectedItem.items!=null){
        this.selectedItem.items.push(menu);
      }else{
        this.selectedItem.items=[menu];
      }
      this.menuItems = Object.assign([],this.menuItems);
      // this.selectedItem =menu;
      // this.$forceUpdate();
      this.reload +=1;
    },
    onRemove(){

      this.menuItems = Object.assign([],this.menuItems);
      this.selectedItem =menu;
    }
  }

}
</script>

<style>

</style>
