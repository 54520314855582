<template>
  <div class="hello">
    {{ $t('message') }}
    <v-btn @click="$i18n.locale='ko'">
      한국어
    </v-btn>
    <v-btn @click="$i18n.locale='en'">
      English
    </v-btn>
  </div>
</template>

<script>

export default {
  components:{
  }
}
</script>

<style>

</style>
